import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ["preview", "productSelect", "variantSelect", "countrySelect", "stateSelect", "error"]
  static values = { products: Array, countries: Array, loading: Boolean }

  connect() {
    this.loadingValue = true
    this.initializeProducts()
  }

  // Sets up initial product selection, variants, and location data
  async initializeProducts() {
    try {
      const product = this.productsValue[0]
      if (!product) return

      this.productSelectTarget.value = product.id
      
      if (product.variants?.length) {
        this.populateVariants(product.variants)
        this.updatePreviewImage(product.variants[0])
      }

      if (this.countrySelectTarget.value) {
        await this.fetchSubregions(this.countrySelectTarget.value)
      }
    } catch (error) {
      console.error("Error initializing products:", error)
      this.errorTarget.textContent = "Sorry, something went wrong fetching the product details. Please try again later."
      this.errorTarget.classList.remove('hidden')
    } finally {
      this.loadingValue = false
    }
  }

  // Handles product selection change (variants and preview image)
  async handleProductChange() {
    const productId = parseInt(this.productSelectTarget.value)
    const selectedProduct = this.productsValue.find(p => p.id === productId)
    
    if (!selectedProduct) {
      console.warn("Product not found for id:", productId)
      return
    }

    if (selectedProduct.variants?.length) {
      this.populateVariants(selectedProduct.variants)
      this.updatePreviewImage(selectedProduct.variants[0])
    }
  }

  // Updates variant select options for a given product
  populateVariants(variants) {
    this.variantSelectTarget.innerHTML = ""
    variants.forEach(variant => {
      this.variantSelectTarget.add(new Option(variant.name, variant.external_id))
    })
  }

  // Handles variant selection change (updates preview image)
  handleVariantChange() {
    const productId = parseInt(this.productSelectTarget.value)
    const variantId = this.variantSelectTarget.value
    const selectedProduct = this.productsValue.find(p => p.id === productId)
    const selectedVariant = selectedProduct?.variants.find(v => v.external_id === variantId)

    if (selectedVariant) {
      this.updatePreviewImage(selectedVariant)
    }
  }

  // Updates the preview image with variant details
  updatePreviewImage(variant) {
    if (variant?.image) {
      this.previewTarget.src = variant.image
      this.previewTarget.alt = variant.name
    }
  }

  // Handles country selection change (subregion fetch)
  async handleCountryChange() {
    const countryCode = this.countrySelectTarget.value
    if (countryCode) {
      await this.fetchSubregions(countryCode)
    }
  }

  // Fetches and populates subregions for selected country
  async fetchSubregions(countryCode) {
    try {
      const { data: subregions } = await axios.get(`/addresses?country=${countryCode}`)
      
      this.stateSelectTarget.innerHTML = ""
      subregions.forEach(([name, value]) => {
        this.stateSelectTarget.add(new Option(name, value))
      })
    } catch (error) {
      console.error("Error fetching subregions:", error)
    }
  }

  // Handles changes to loading state
  loadingValueChanged() {
    const classList = this.element.classList
    this.loadingValue
      ? classList.add('opacity-50', 'pointer-events-none')
      : classList.remove('opacity-50', 'pointer-events-none')
  }
}
