import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['target'];

  static values = {
    delay: { type: Number, default: 0 }
  }

  connect() {
    setTimeout(() => {
      this.targetTarget.click();
    }, this.delayValue);
  }
}
