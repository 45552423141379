import { Controller } from "@hotwired/stimulus"

// This Stimulus controller manages the free guide selection functionality in app/views/redesign2019/newsletter/index.html.erb (i.e., the /newsletter page)
// It connects the radio button selection to a hidden input field in app/components/app/inline_newsletter_form_component/inline_newsletter_form_component.html.erb
//
// The flow works like this:
// 1. User selects a radio button in newsletter/index.html.erb
// 2. This controller updates the hidden input in inline_newsletter_form_component.html.erb
// 3. When the form submits, the selected guide value is sent to Newsletter::SubscriptionsController.
// 4. The selected guide value is then utilized in derive_newsletter_email_lists.rb to add the subscriber to the correct email list
//
// File dependencies:
// - app/views/redesign2019/newsletter/index.html.erb (contains the radio buttons)
// - app/components/app/inline_newsletter_form_component/inline_newsletter_form_component.html.erb (contains the hidden input)
// - app/controllers/newsletter/subscriptions_controller.rb (processes the form submission)
export default class extends Controller {
  // This target connects to the hidden input field in inline_newsletter_form_component.html.erb
  // The input has this HTML: data-guide-selection-target="guideInput"
  static targets = ["guideInput"]

  // When this controller connects to the DOM element with data-controller="guide-selection",
  // it looks for any pre-selected radio button and updates the hidden input accordingly
  connect() {
    // Find any radio button that's already checked in newsletter/index.html.erb
    // These radio buttons have values: "bdnf-guide", "omega-3-guide", or "how-to-train-guide"
    const defaultGuide = document.querySelector('input[name="selected_guide"]:checked')
    if (defaultGuide) {
      this.updateSelectedGuide(defaultGuide.value)
    }
  }

  // This method is triggered by clicking any radio button with data-action="guide-selection#selectGuide"
  // The radio buttons are in newsletter/index.html.erb inside the guide-options div
  selectGuide(event) {
    const selectedInput = event.target

    // We need special handling for the checkbox/radio button interaction because:
    // 1. In index.html.erb, we have two different input types that need to be mutually exclusive:
    //    - Radio buttons with name="selected_guide" (which automatically work as a group)
    //    - A checkbox with name="all_guides" (which is independent by default)
    //
    // 2. While radio buttons automatically uncheck each other when they share the same 'name',
    //    they won't automatically interact with our checkbox. This is why we need custom code
    //    to create this mutual exclusivity between different input types.

    // Handle the "Send me all three guides" checkbox
    if (selectedInput.type === 'checkbox') {
      // When the checkbox is checked, we need to manually uncheck all radio buttons
      // because checkboxes don't automatically affect other input types
      if (selectedInput.checked) {
        document.querySelectorAll('input[name="selected_guide"]').forEach(radio => {
          radio.checked = false
        })
      }
    } else {
      // When any radio button is selected, we need to manually uncheck the checkbox
      // because radio buttons don't automatically affect checkboxes, even though
      // we want them to be mutually exclusive
      document.querySelector('input[name="all_guides"]').checked = false
    }


    // Get the value from the clicked radio button
    // Values correspond to different guides:
    // - "bdnf-guide" -> Cognitive Enhancement Blueprint
    // - "omega-3-guide" -> Omega-3 Guide
    // - "how-to-train-guide" -> How to Train Guide
    // - "all-guides" -> All three guides
    this.updateSelectedGuide(event.target.value)
  }

  // Updates the hidden input field in the newsletter form component
  // The hidden input is in inline_newsletter_form_component.html.erb:
  // <%= hidden_field_tag 'newsletter[selected_guide]', nil, data: { guide_selection_target: "guideInput" } %>
  //
  // This value will be submitted with the form and processed by:
  // app/controllers/newsletter/subscriptions_controller.rb
  // which then determines which email list to add the subscriber to based on their guide selection
  updateSelectedGuide(value) {
    this.guideInputTarget.value = value
  }
}