import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Notes:
  // When the page loads, Stimulus looks for elements with data-controller="welcome-survey"
  // The data-controller in /member_dashboard/welcome_surveys/new.html.erb tells Stimulus to connect that element to this controller

  // This tells Stimulus to look for elements with:
  // - data-welcome-survey-target="skipForm" (store it in this.skipFormTarget)
  // - data-welcome-survey-target="skipButton" (store it in this.skipButtonTarget)
  // - data-welcome-survey-target="warningMessage" (store it in this.warningMessageTarget)
  // - data-welcome-survey-target="errors" (store it in this.errorsTarget)
  static targets = ["skipForm", "skipButton", "warningMessage", "errors"]

  // This tells Stimulus to track whether we've shown the warning message
  // It looks for data-welcome-survey-has-warned-value on the element
  // The value is stored in this.hasWarnedValue and defaults to false
  static values = {
    hasWarned: { type: Boolean, default: false }
  }

  // This method is called when the skip button is clicked
  // It intercepts the first click to show a warning message
  // On subsequent clicks, it allows the form to submit normally
  skip(event) {
    // If we haven't shown the warning message yet
    if (!this.hasWarnedValue) {
      // Prevent the form from submitting
      event.preventDefault()
      // Show the warning message
      this.warningMessageTarget.style.display = "block"
      // Update the skip button text
      this.skipButtonTarget.value = "Skip for now (yes, I'm sure)"
      // Mark that we've shown the warning
      this.hasWarnedValue = true
      
      // Hide any error messages if they exist
      // We check if we have the errors target because errors might not always be present
      if (this.hasErrorsTarget) {
        this.errorsTarget.style.display = "none"
      }

      // Find all field_with_errors divs and unwrap their contents
      // This removes the error styling from individual form fields
      this.element.querySelectorAll('.field_with_errors').forEach(errorDiv => {
        // Move all children out of the error div and place them before the div
        while (errorDiv.firstChild) {
          errorDiv.parentNode.insertBefore(errorDiv.firstChild, errorDiv)
        }
        // Remove the now-empty error div
        errorDiv.remove()
      })
    }
    // If hasWarnedValue is true, we don't prevent the event
    // so the form submits normally
  }
} 