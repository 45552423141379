import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "button"]

  connect() {
    this.clickOutside = this.clickOutside.bind(this)
    this.handleKeydown = this.handleKeydown.bind(this)
    this.updatePosition = this.updatePosition.bind(this)

    document.addEventListener("click", this.clickOutside)
    document.addEventListener("keydown", this.handleKeydown)
    window.addEventListener("resize", this.updatePosition)
  }

  disconnect() {
    document.removeEventListener("click", this.clickOutside)
    document.removeEventListener("keydown", this.handleKeydown)
    window.removeEventListener("resize", this.updatePosition)
  }

  toggle(event) {
    event.stopPropagation()
    this.isOpen ? this.close() : this.open()
  }

  open() {
    this.updatePosition()
    this.menuTarget.classList.remove("hidden")
    this.menuTarget.classList.add("active")
    this.buttonTarget.setAttribute("aria-expanded", true)
  }

  close() {
    this.menuTarget.classList.remove("active")
    this.menuTarget.classList.add("hidden")
    this.buttonTarget.setAttribute("aria-expanded", false)
  }

  clickOutside(event) {
    if (!this.element.contains(event.target) && this.isOpen) {
      this.close()
    }
  }

  handleKeydown(event) {
    if (event.key === "Escape" && this.isOpen) {
      this.close()
      this.buttonTarget.focus()
    }
  }

  updatePosition() {
    const buttonRect = this.buttonTarget.getBoundingClientRect()
    const dropdownRect = this.element.getBoundingClientRect()
    const menuWidth = 200
    
    if (buttonRect.left + menuWidth > window.innerWidth) {
      this.menuTarget.style.right = `${dropdownRect.right - buttonRect.right}px`
      this.menuTarget.style.left = 'auto'
    } else {
      this.menuTarget.style.left = `${buttonRect.left - dropdownRect.left}px`
      this.menuTarget.style.right = 'auto'
    }
  }

  get isOpen() {
    return this.menuTarget.classList.contains("active")
  }
}
