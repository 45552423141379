// app/javascript/controllers/scroll_invocation_controller.js
import { Controller } from "@hotwired/stimulus"

// This controller manages the scroll-based animation of the newsletter modal
// As the user scrolls down the page, the modal slides up from below the viewport
// The animation is linear, meaning the modal moves up at a constant rate relative to scroll position
export default class extends Controller {
    // This tells Stimulus to look for elements with:
    // - data-scroll-invocation-target="modal": The container div that wraps the modal content
    //   and starts positioned below viewport with transform: translateY(120%)
    // - data-scroll-invocation-target="overlay": The dark background overlay
    //   that fades in/out with the modal animation
    static targets = ["modal", "overlay"]

    // The connect method is called automatically when the controller is connected to the DOM
    // It sets up the initial state and scroll event listener
    connect() {
        // Safety check: if we can't find the modal or overlay targets, exit early
        // This prevents errors if the DOM structure isn't what we expect
        if (!this.hasModalTarget || !this.hasOverlayTarget) return

        // Make elements visible but maintain initial states
        this.modalTarget.style.visibility = 'visible'
        this.overlayTarget.style.visibility = 'visible'

        // Add pointer-events: none to allow clicking through initially
        // Without this, you can't click anywhere on the page when the page loads
        // even though the modal is below the viewport
        this.modalTarget.style.pointerEvents = 'none'
        this.overlayTarget.style.pointerEvents = 'none'

        // Bind the scroll handler to maintain the correct 'this' context
        // This ensures 'this' refers to our controller instance inside handleScroll
        this.handleScroll = this.handleScroll.bind(this)

        // Define the scroll range where the animation occurs
        // START_THRESHOLD: Modal starts moving up when user scrolls past 200px
        // END_THRESHOLD: Modal finishes moving up when user scrolls past 900px
        // This creates a 700px window where the animation happens
        this.START_THRESHOLD = 200
        this.END_THRESHOLD = 900

        // Set initial positions
        this.modalTarget.style.transform = "translateY(120%)"
        this.overlayTarget.style.opacity = "0"

        // Add scroll event listener to trigger animation
        window.addEventListener('scroll', this.handleScroll)
    }

    // Clean up event listener when the controller is disconnected
    // This prevents memory leaks and ensures we don't try to animate
    // elements that no longer exist
    disconnect() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    // Checks if another modal is already open on the page
    // Returns true if another modal is open, false otherwise
    //
    // This method and check in handleScroll were added when incoporating the premium_content_scroll_modal field test
    // This prevents our scroll-triggered modal from showing on Aliquot and Q&A pages if another modal is already open
    // For example, if the modal that displays on Q&A pages when the user clicks within blocked_content_component.html.erb
    // is already open, we don't want to show our scroll-triggered modal
    isAnotherModalOpen() {
        // Look for modal elements in the core-modal-component
        // These elements have data-core-modal-component-target="toggleable" attribute
        const modalElements = document.querySelectorAll('[data-core-modal-component-target="toggleable"]')
        
        // A modal is visible/open when it DOESN'T have the 'hidden' class
        // The core-modal-component toggles the 'hidden' class to show/hide modals
        const visibleModals = Array.from(modalElements).filter(modal => {
            return !modal.classList.contains('hidden')
        })
        
        // Now we need to exclude our own scroll modal from the count
        // We only care about OTHER modals that might be open
        const otherVisibleModals = visibleModals.filter(modal => {
            // Check if this modal is not part of our scroll controller
            return !this.element.contains(modal) && !modal.contains(this.element)
        })
        
        // Return true if we found at least one other visible modal
        return otherVisibleModals.length > 0
    }

    // This method is called on every scroll event
    // It calculates how far the modal should be moved based on scroll position
    handleScroll() {
        // NEW CODE: Check if another modal is already open
        // If so, hide our scroll modal and don't animate it
        if (this.isAnotherModalOpen()) {
            // Reset our scroll modal to fully hidden state
            this.modalTarget.style.transform = "translateY(120%)"
            this.overlayTarget.style.opacity = "0"
            this.modalTarget.style.pointerEvents = 'none'
            this.overlayTarget.style.pointerEvents = 'none'
            return; // Exit early - don't animate while another modal is open
        }

        // Get current scroll position from top of page
        const scrollPos = window.scrollY

        // Calculate the animation progress ratio (0 to 1)
        let ratio = 0
        if (scrollPos < this.START_THRESHOLD) {
            // Before animation starts: keep modal fully below viewport
            ratio = 0
        } else if (scrollPos >= this.END_THRESHOLD) {
            // After animation ends: keep modal fully visible
            ratio = 1
        } else {
            // During animation: calculate linear progress
            // Example: if we're halfway between START and END thresholds,
            // ratio will be 0.5
            ratio = (scrollPos - this.START_THRESHOLD) / (this.END_THRESHOLD - this.START_THRESHOLD)
        }

        // Update modal position (slide up)
        const translateY = 120 - (120 * ratio)
        this.modalTarget.style.transform = `translateY(${translateY}%)`

        // Update overlay opacity (fade in)
        this.overlayTarget.style.opacity = ratio.toString()

        // Only enable pointer events when the overlay is significantly visible
        // This allows clicks to pass through until modal is at least 30% visible
        const INTERACTION_THRESHOLD = 0.3 

        // Enable pointer events once the modal starts becoming visible
        // This allows clicks to pass through until modal is at least partially visible
        if (ratio > INTERACTION_THRESHOLD) {
            this.modalTarget.style.pointerEvents = 'auto'
            this.overlayTarget.style.pointerEvents = 'auto'
        } else {
            this.modalTarget.style.pointerEvents = 'none'
            this.overlayTarget.style.pointerEvents = 'none'
        }
    }
}
