import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleable'];

  static values = {
    open: { type: Boolean, default: false },
    openOnConnect: { type: Boolean, default: false }, // Controls if modal should auto-open when connected to the DOM
    redirectOnClose: { type: String, default: '' }, // If not empty, redirects to the specified URL after the modal is closed
    shouldSetNewsletterModalDismissedCookie: { type: Boolean, default: false } // Set to true in newsletter_modal_component.html.erb, added to the data attribute (data-core-modal-component-should-set-newsletter-modal-dismissed-cookie-value="true") in modal_component.rb
  };

  // Use case for this connect() method:
  // 1. The data attribute (data-core-modal-component-open-on-connect-value="true")
  //    that was added by modal_component.rb is read here as this.openOnConnectValue
  // 2. If true (we set open_on_connect to true in _enhanced_notes_membership_form_modal.html.erb and in stripe_checkout_offer.erb),
  //    we automatically open the modal by setting this.openValue = true
  connect() {
    // Check for the data attribute that was added by modal_component.rb
    if (this.openOnConnectValue) {
      this.openValue = true;  // This triggers openValueChanged() to show the modal
    }
  }

  open(evt) {
    // This block handles the special case for the "Click here to login" link
    // in the _gated_aliquot.html.erb file
    if (evt.target.tagName === 'A' && evt.target.getAttribute('data-turbo') === 'false') {
      // If the clicked element is a link (<a> tag) AND has data-turbo="false"...
      return; // ...do nothing, allowing the default link behavior (navigation)
    }

    // For all other cases (i.e., not the login link)...
    evt.preventDefault(); // Prevent the default action (like following a link)
    this.openValue = true; // Open the modal
  }

  dismiss(evt) {
    evt.preventDefault();
    this.openValue = false;

    if (this.shouldSetNewsletterModalDismissedCookieValue) {
      // Set the newsletter_modal_dismissed cookie to true
      // This cookie is used to prevent the modal from being shown again
      // We set the cookie to expire in 24 hours
      // This mimics Substack's approach to modals
      const date = new Date();
      date.setTime(date.getTime() + (24 * 60 * 60 * 1000)); // 24 hours from now
      const expires = "expires=" + date.toUTCString();
      document.cookie = "newsletter_modal_dismissed=true; " + expires + "; path=/";
    }
  }

  // We have to have something to call when using the :stop modifier
  // ont the click action.  This is it.
  noop() { }

  openValueChanged(newValue, oldValue) {
    // -----------------------------------------------------
    // Parameters explained:
    // -----------------------------------------------------
    // newValue: The new state of openValue that triggered this method
    //          - true when modal is being opened
    //          - false when modal is being closed
    // oldValue: The previous state of openValue before this change
    //          - undefined when controller first initializes
    //          - true if modal was previously open
    //          - false if modal was previously closed
    // -----------------------------------------------------
    // When is this method called?
    // -----------------------------------------------------
    // 1. When controller initializes (newValue: false, oldValue: undefined)
    // 2. When this.openValue = true is set in connect() due to openOnConnect
    //    (newValue: true, oldValue: false)
    // 3. When modal is closed via dismiss() 
    //    (newValue: false, oldValue: true)
    if (newValue) {
      // Modal is being opened (this.openValue was set to true)
      // This happens either:
      // 1. Automatically via openOnConnect in connect()
      // 2. Manually via a button click that sets openValue = true
      this.toggleableTargets.forEach(el => el.classList.remove('hidden'));
    } else {
      // Modal is being closed (this.openValue was set to false)
      // This happens either:
      // 1. On initial controller connection (openValue defaults to false)
      // 2. When dismiss() is called, setting openValue = false
      this.toggleableTargets.forEach(el => el.classList.add('hidden'));

      // -----------------------------------------
      // Redirect Logic
      // -----------------------------------------
      // Only redirect if ALL these conditions are true:
      // 1. redirectOnCloseValue exists and isn't empty
      // 2. oldValue === true (meaning modal was actually open before, but has now been closed)
      //
      // Use case: When closing the modal in stripe_checkout_offer.erb, we direct back to crowdsponsor_path
      if (this.redirectOnCloseValue.trim().length > 0 && oldValue === true) {
        window.location.href = this.redirectOnCloseValue;
      }
    }
  }
}