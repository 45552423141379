import { Controller } from "@hotwired/stimulus"

// This controller manages the responsive button text and email input padding for the 'bdnf' and 'how_to_train' newsletter modal variants 
// The button text changes based on screen width, and the email input's right padding is adjusted to prevent text from going behind the button
export default class extends Controller {
    // When the page loads, Stimulus looks for elements with data-controller attribute
    // The data-controller="newsletter-modal" in newsletter_modal_component.html.erb tells Stimulus to connect that element to this controller

    // This tells Stimulus to look for elements with:
    // - data-newsletter-modal-target="emailInput" (this is passed as 'newsletter_modal_target': "emailInput" as part of a data object on the EmailVerificationInputComponent)
    // - data-newsletter-modal-target="submitButton" (this is passed as 'newsletter_modal_target': "submitButton" as part of a data object on the submit button)
    static targets = ["emailInput", "submitButton"]

    // This tells Stimulus to look for elements with data-newsletter-modal-variant-value
    // The value is set in newsletter_modal_component.html.erb as data-newsletter-modal-variant-value="<%= @variant %>"
    // This allows us to access the current variant (either 'bdnf' or 'how_to_train') via this.variantValue
    // The variant determines which text to show on the button in different states
    static values = {
        variant: String
    }

    // The connect method is called automatically when the controller is connected
    // It sets up the initial button text and adds a resize event listener
    connect() {
        // Set initial button text and padding
        this.updateButtonText();

        // Add resize event listener to update button text when window size changes
        window.addEventListener('resize', this.updateButtonText.bind(this));
    }

    // Remove the resize event listener when the controller is disconnected
    disconnect() {
        window.removeEventListener('resize', this.updateButtonText.bind(this));
    }


    // This method updates the button text based on the window's width and variant
    // It also adjusts the email input's right padding to match the button width
    updateButtonText() {
        const buttonTexts = {
            'bdnf': {
                default: 'Download Blueprint',
                small: 'Download'
            },
            'how_to_train': {
                default: 'Download Guide',
                small: 'Download'
            }
        }

        const variantTexts = buttonTexts[this.variantValue] // Get text config for current variant

        // Use shorter text on mobile screens (< 475px)
        const buttonText = window.innerWidth < 475
            ? variantTexts.small
            : variantTexts.default

        this.submitButtonTarget.textContent = buttonText

        // When the button text changes, we need to update the right padding on the email input
        this.setPaddingRightOnEmailInput();
    }

    // This method ensures the email input's right padding matches the button width
    // This prevents typed text from being hidden behind the button
    setPaddingRightOnEmailInput() {
        // Get the current width of the button
        const buttonWidth = this.submitButtonTarget.offsetWidth;

        // Set the padding-right of the email input to match the button's width plus 10px
        this.emailInputTarget.style.paddingRight = `${buttonWidth + 10}px`;
    }
}